.sgi {
  width: 100% !important;
}
.pro-head {
  font-family: "Montserrat", sans-serif;
  font-family: 18px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
}
.pro-txt {
  font-size: 14px;
  font-weight: 300;
  color: #7b8a91;
  font-family: "Open sans", sans-serif;
  line-height: 20px;
}

.pro-id {
  font-size: 14px;
  font-weight: 600;
  color: #1818c3;
  font-family: "Open sans", sans-serif;
  line-height: 20px;
}

.pro-list {
  font-size: 14px;
  font-weight: 300;
  color: #7b8a91;
  font-family: "Open sans", sans-serif;
  line-height: 25px;
}
.pro-info {
  font-weight: 700;
}
