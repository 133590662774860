.sgi {
  width: 100% !important;
}
.fwt {
  font-weight: 700;
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}
.spt {
  margin-top: 35px;
}
.dftr, .saamar-manage-li{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #7b8a91;
    line-height: 26px;
    font-weight: 300;
    text-align: justify;
}
