.circular-txt {
  font-size: 14px;
  font-style: "Open Sans", sans-serif;
  font-weight: 300;
  color: #7b8a91;
}
.circle-table-data {
  font-size: 15px;
  font-style: "Open Sans", sans-serif;
  font-weight: 300;
  color: #7b8a91;
}

.table.cir-table > tbody > tr > td {
  padding: 17px 21px;
}
@media (max-width: 600px) {
  .table.cir-table > tbody > tr > td {
    padding: 7px 5px;
  }
}
