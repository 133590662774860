.test-bg {
  background-color: #6d6d6d;
  min-height: 570px;
}
.test-head {
  color: #ffffff;
  text-align: center;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold;
  font-size: 30px;
}
.test-para {
  background: #f4f4f4 !important;
  padding: 25px 29px;
  line-height: 32px;
  border-radius: 10px;
  color: #7b8a91;
  text-align: justify;
}
.test-bg .carousel-indicators {
  display: none !important;
}
.profile-image {
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
}
.profile-image img {
  /* height: 100px; */
  width: 100px;
  border-radius: 500px;
  -moz-border-radius: 500px;
  -webkit-border-radius: 500px;
  margin-bottom: 10px;
}
.test-p {
  color: #fff !important;
  line-height: 26px !important;
  font-size: 14px !important;
  margin: 8px 0 !important;
  font-family: "Open Sans", sans-serif !important;
}
