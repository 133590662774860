.Tech {
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}
.tech-para {
  color: #7b8a91;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 26px;
  font-weight: 300;
  margin: 10px 0;
  text-align: justify;
}
.std {
  padding: 35px 0;
}
.pdg {
  height: 388px;
  width: 100%;
}
.pdg1 {
  text-align: center;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .pdg {
    height: 298px;
    width: 100%;
    margin-bottom: 15px;
  }
  .tri-angle {
    width: 100%;
  }
  .teach-ped-img {
    width: 100%;
  }
}
