@font-face {
  font-family: "Montserrat-style";
  src: url("../../assets/fonts/Montserrat-style.ttf");
}
@font-face {
  font-family: "OpenSans-style";
  src: url("../../assets/fonts/OpenSans-style.ttf");
}
.bgser {
  background: #f5fcfd;
  padding: 63px 0;
}
.service-head {
  text-align: center;
  font-weight: bold;
  /* margin-bottom: 25px; */
  font-family: "Open Sans", sans-serif !important;
  font-size: 30px;
}

.svi {
  color: #00bcd4;
  font-weight: bold;
}
.fa {
  color: #a1a1a1;
  font-size: 50px;
}
.gtr {
  font-size: 16px;
}
.cfr {
  color: #7b8a91;
  font-size: 14px;
  font-family: "OpenSans-style";
  line-height: 30px;
}
.dero {
  padding: 10px 0;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .bgser {
    text-align: center;
  }
}
