.sgi {
  width: 100% !important;
}
.crit-head {
  color: #c23220 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 19.8px;
}
.mpu {
  margin-top: 30px;
  text-align: justify;
}
.crit-text {
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 550;
  line-height: 19.8px;
  color: #333333 !important;
}
.crit-para {
  font-size: 14px;
  font-weight: 300;
  color: #7b8a91;
  font-family: "Open sans", sans-serif;
  line-height: 20px;
}
.crit-info-para {
  line-height: 25px !important;
}
.crit-info {
  font-weight: 700;
  color: #333333;
  font-family: "Montserrat", sans-serif !important;
}
.crit-log {
  width: 28%;
  background: #a9fbfd;
  font-size: 14px;
  font-weight: 300;
  color: #7b8a91;
  font-family: "Open sans", sans-serif;
  line-height: 20px;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .durcww {
    width: 100%;
  }
  .crit-log {
    width: 100%;
  }
}
