.learn-text {
  color: #7b8a91;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 26px;
  font-weight: 300;
  margin: 10px 0;
  text-align: justify;
}
.learn-head {
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}
.learn-text-fr {
  color: #7b8a91 !important;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 26px;
  font-weight: 300;
  margin: 10px 0;
  text-align: justify;
}
.learn-text-fr::first-letter {
  font-weight: bold;
  color:#333333;
}
