.sgi {
  width: 100% !important;
}
.vti {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: black;
}
.pqe {
  font-family: "Open Sans", sans-serif;
  color: #7b8a91;
  font-weight: 300;
  font-size: 14px;
}
.kvs {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: black;
}
.li-ul {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #7b8a91;
}
.ion {
  /* width: 85%;
  height: 176px; */
  width: 100%;
  height: 180px;
  object-fit: cover;
  padding: 10px 0;
}
.vm-ul > li {
  line-height: 22px;;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .sgi {
    height: 200px important;
  }
}
