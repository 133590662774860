.qrtu {
  text-align: center;
  padding: 20px 0;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
}
.svi {
  color: #00bcd4;
  font-weight: bold;
}
.ifm {
  width: 100%;
  height: 450px;
}
@media (max-width: 700px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .ifm {
    width: 100%;
    height: 400px;
  }
}
