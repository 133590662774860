.cal-text {
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
  color: #333333;
}

.cal-table-data{
  font-size: 14px;
  font-family: "Opens Sans", sans-serif;
  color: #7b8a91;;

}
.table.cal-table > thead > tr > th,
.table.cal-table > tbody > tr > td {
  padding: 15px 10px;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .sgi {
    height: 210px !important;
  }
  .table.cal-table > thead > tr > th,
.table.cal-table > tbody > tr > td {
  padding: 15px 3px;
}
}
