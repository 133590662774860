.scim {
  width: 100%;
  /* height: 235px; */
  display: block;
  height: 200px;
  object-fit: cover;
}
.sarm {
  text-align: center;
  font-weight: bold;
  /* margin-bottom: 25px; */
  font-family: "Open Sans", sans-serif !important;
  font-size: 30px;
}

.getr {
  position: relative;
  width: 100%;
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
}
.getr:hover .overlay {
  opacity: 1;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}
.gto {
  /* padding: 30px 0; */
}
@media (max-width: 700px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .overlay {
    width: 97%;
  }
  .scim {
    width: 97%;
  }
}
