.gg {
  height: 450px;
  width: 100%;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem !important;
  padding-bottom: 10rem !important;
  color: #fff;
  text-align: center;
  font-size: 2.75rem !important;
}
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next-icon {
  display: none !important;
}
.carousel-caption p {
  display: none !important;
}
.carousel-caption h3 {
  font-size: 40px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
}
.mog_cont {
  position: absolute !important;
  top: 4% !important;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  font-size: 24px;
}
.marq {
  position: relative !important;
}
.carousel-indicators > button {
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .marquee {
    font-size: 20px;
  }
}
