.stu-head {
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 550;
  line-height: 19.8px;
  color: #333333;
}
.stu-text {
  font-size: 14px;
  font-weight: 300;
  color: #7b8a91;
  font-family: "Open sans", sans-serif;
  line-height: 31px;
}
.stu-text::first-letter {
  font-weight: bold;
  color: #333333;
}
.stu-img {
  width: 100%;
  height: 400px;
  padding: 10px 0;
}
.info-stnd-col {
  border: 1px solid #4472c5;
  border-radius: 10px;
  background-color: #4472c5;
  color: #faee35;
  font-weight: bold;
}
.stu-table-data {
  font-size: 14px;
  font-weight: 300;
  color: #7b8a91;
  font-family: "Open sans", sans-serif;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .std-img {
    margin-top: 10px;
  }
  /* .table.student-table {
  display: none;
  } */
}
