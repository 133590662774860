.bg-light {
  background-color: #414141 !important;
  opacity: 0.9 !important ;
  color: white !important;
}
.text-reset {
  text-decoration: none;
}
.mt-3 {
  padding-top: 50px;
  margin-top: 0rem !important;
}
.ft,
.text-reset {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 30px;
  font-weight: 100 !important;
  /* text-align: center; */
}
.fab {
  font: normal normal normal 14px/1 FontAwesome;

  font-size: 24px;
}
.mgr {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #f8f8f8;
  font-weight: 600 !important;
}
.mx-auto {
  padding: 0;
}
.cdf {
  border-bottom: 1px solid white;
}
.iol a i {
  background: #757575;
  text-align: center;
  color: #ffffff;
  line-height: 35px;
  font-size: 18px;
  padding: 2%;
  border-radius: 24px;
}
.fab.fa-facebook-f {
  padding: 8px 12px;
}
@media (min-width: 300px) {
  .text-md-start {
    text-align: left !important;
    padding: 0 0 0 24px;
  }
}
