.web-txt {
  color: #7b8a91;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 26px;
  font-weight: 300;
  margin: 10px 0;
  text-align: justify;
}
.web-email {
  color: #3b6bfb;
  font-weight: bold;
}
