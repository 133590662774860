.sgi {
  width: 100% !important;
}

.gallery-other-img {
  width: 100%;
  height: 250px;
  padding: 0 0 20px 0;
  object-fit: cover;
}

.other-txt {
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
  color: #333333;
  font-weight: 700;
}

@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
 
}
