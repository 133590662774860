.sys-text {
  color: #7b8a91;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 26px;
  font-weight: 300;
  margin: 10px 0;
  text-align: justify;
}
.sys-head{
  color: black;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 23px;
  font-size: 18px;
}
.oty {
  margin-top: 20px;
}
li {
  line-height: 36px;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .otop {
    width: 100%;
  }
}
