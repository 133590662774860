.sgi {
  width: 100% !important;
}
.esid {
  font-family: "Montserrat", sans-serif;
  color: #333333;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}
.mman {
  color: #7b8a91;
  font-size: 14px;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  line-height: 21px;
  margin-bottom: auto;
}
.lcu{
    margin-top: 25px;
}
.wqn{
    font-weight: 700;
}

.mmanaa {
  color: #337ab7;
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    line-height: 21px;
    margin-bottom: auto;
  }