.sch-txt {
  color: #7b8a91;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 26px;
  font-weight: 300;
  margin: 10px 0;
  text-align: justify;
}
.sch-head {
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-size: 18px !important;
  font-weight: 600;
  line-height: 23px;
}
.sch-image{
  width: 100%;
  /* object-fit: cover; */
  padding: 0 0 20px 0;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .sch-g {
    margin-top: 10px;
  }
}
