.olp {
  margin-top: 25px;
}
.submit {
  background-color: #00bcd4;
  color: #ffffff !important;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-weight: 600 !important;
}
.btn-fld-sma {
  background-color: #00bcd4;
  color: #ffffff !important;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 7px 20px;
  font-weight: 600 !important;
}
.text-area {
  height: 75px;
  width: 100%;
  border: 1px solid #ddcece;
  border-radius: 6px;
}
