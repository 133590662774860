.sgi {
  width: 100% !important;
}

.gallery-national-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  padding: 0 0 20px 0;
}

@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  /* .gotq {
    margin: 19px 0;
  } */
}