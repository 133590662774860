.accordion__item {
  /* border-top: 0 !important; */
  margin-bottom: 10px;
}
.accordion {
  border: 0 !important;
}

.accordion__button {
  /* margin: 10px 0 !important; */
  background-color: #ffffff !important;
  cursor: pointer;
  padding: 0px !important;
  text-align: left;
  border: 1px solid #cfcfcf !important;
  border-bottom: 0 !important;
  font-weight: normal !important;
  font-size: 15px !important;
  font-family: "Open Sans", sans-serif !important;
  color: #414141 !important;
}
.accordion__panel {
  background-color: #fff;
  padding: 20px;
  animation: fadein 0.35s ease-in;
  border: 1px solid #c6c5c4;
  line-height: 26px !important;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif !important;
  text-align: justify;
  margin-top: -12px !important;
  color: #7b8a91;
}
/* .accordion__panel > * {
  border: 0;
} */

.freq-head {
  text-align: center;
  font-weight: 700;
  font-family: "Open Sans", sans-serif !important;
  font-size: 30px;
}

.svi {
  color: #00bcd4;
}
.ngt {
  background: #f7f7f7;
}

.accordion__button::after {
  display: inline-block;
  content: "";
  height: 0px !important;
  width: 0px !important;
  margin-right: 0px !important;
  border-bottom: 0px solid currentColor !important;
  border-right: 0px solid currentColor !important;
  transform: rotate(-45deg);
}
.accordion__button:before {
  display: inline-block;
  content: "";
  height: 0px !important;
  width: 0px !important;
  margin-right: 0px !important;
  border-bottom: 0px solid currentColor !important;
  border-right: 0px solid currentColor !important;
}
.faq1-pt-down {
  color: #23527c;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-body {
  border: 1px solid #cfcfcf;
}
