.culum-extr-p {
  line-height: 26px !important;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif !important;
  text-align: justify;
}
.staff-top {
  font-weight: bold;
  font-size: 18px;
  color: #338baa !important;
  font-family: "Montserrat", sans-serif !important;
}
/* .staff-headr-r {
  color: #338baa !important;
} */
.nfo {
  color: #5fb151 !important;
  font-weight: 700;
  font-family: "Open Sans", sans-serif !important;
  font-size: 24px;
}

.staff-design {
  color: #804cab !important;
  border: 1px solid black;
  padding: 10px !important;
  border-radius: 10px;
  width: 50%;
  font-family: "Open Sans", sans-serif !important;
}
.staff-design > h4 {
  font-size: 21px !important;
}
.staff-img {
  width: 100%;
  height: 400px;
  padding: 15px 0;
  margin-top: 15px;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .staff-ige {
    width: 100%;
  }
}
