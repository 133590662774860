.announ-txt {
  font-weight: bold;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
}
.notice-announ-img {
  width: 100%;
  padding: 10px 0;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
}
