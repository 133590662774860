.sgi {
  width: 100% !important;
}
.gty {
  width: 95%;
  height: 318px;
}
.eda {
  margin-top: 50px;
}
.pgr {
  color: #7b8a91;
  line-height: 26px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  text-align: justify;
}

@media (max-width: 600px) {
  .gty {
    height: 273px;
  }
  /* styles to apply when the viewport width is 600 pixels or less */
}
