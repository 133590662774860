@font-face {
  font-family: "Montserrat-style";
  src: url("../../assets/fonts/Montserrat-style.ttf");
}
.sct {
  padding: 65px 0;
}
.saamar-con-col-h3,
.dft {
  color: #b22222 !important;
  font-weight: bold;
  font-family: "Open Sans", sans-serif !important;
}
.xtr {
  color: #3366cc;
  font-size: 14px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 300;
}
.dft {
  font-size: 25px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold;
}
.tg {
  height: 261px;
}

.xtr2 {
  color: #ef9c2d;
  font-size: 14px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 300;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .sct {
    text-align: center;
  }
  /* .tg {
    height: 261px;
    width: 97%;
  } */
}
@media (max-width: 1500px) {
  /* Styles go here */
  /* .tg {
    width: 100%;

  } */
  .tg {
    width: 89%;
  }
}
