@font-face {
  font-family: "OpenSans-Regular";
  src: url("../src/assets/fonts/OpenSans-Regular.ttf");
}
p {
  font-family: "OpenSans-Regular" !important;
  font-size: 14px;
}

.sgi {
  width: 100% !important;
}

.container {
  max-width: 1170px !important;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .sgi {
    height: 210px important;
  }
}
