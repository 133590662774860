.sgi {
  width: 100% !important;
}

.form-li {
  color: #7b8a91;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  line-height: 25px;
}
