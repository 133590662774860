.sit{
    color: #7b8a91;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    font-weight: 300;
    margin: 10px 0;
}
.qzr{
    width: 100%;
    height: 350px;
    object-fit: cover;
    padding: 15px 0;
}