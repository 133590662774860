.culum-extr-p {
  line-height: 26px !important;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif !important;
  text-align: justify;
  color: #7b8a91;
}
.pst {
  font-weight: bold;
  color: #333333;
  font-size: 16px;
  font-family: "Montserrat", sans-serif !important;
}
.cpst {
  color: #00bcd4 !important;
}
.extra-head-img {
  width: 100%;
  padding: 12px 0;
  height: 250px;
  object-fit: cover;
}
.extra-head-img1 {
  width: 100%;
  padding: 12px 0;
  /* object-fit: cover; */
  height: 355px;
  margin: 61px 0;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .extra-head-img1 {
    height: 257px;
  }
}
