.sgi {
  width: 100% !important;
}

.gallery-annual-img {
  width: 100%;
  height: 250px;
  padding: 0 0 20px 0;
  object-fit: cover;
}

.annual-txt {
  color: #333333;
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  line-height: 19px;
}
@media (max-width: 600px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  /* .gotq {
    margin: 19px 0;
  } */
}
