.parent-head {
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 550;
  line-height: 19.8px;
  color: #333333;
}
.parent-txt {
  font-size: 14px;
  font-weight: 300;
  color: #7b8a91;
  font-family: "Open sans", sans-serif;
  line-height: 31px;
}
.table-parent-data{
  font-size: 14px;
  font-weight: 300;
  color: #7b8a91 !important;
  font-family: "Open sans", sans-serif;
}