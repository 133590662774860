.dropdown-toggle::after {
  display: none;
}
.bg-primary {
  background: #ffffff !important;
  color: #7b8a91 !important;
  padding: 0;
}
.navbar-light .navbar-nav .nav-link {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #414141 !important;
  font-weight: 700;
}
.navbar-dark .navbar-nav .nav-link {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #414141 !important;
  font-weight: 700;
}

.saamar_logo_hd {
  font-size: 12px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  color: #414141;
  padding: 0 2px;
}
.saamar_logo_hd-sticky {
  color: #fff;
}
.under-sn {
  font-size: 10px !important;
  display: flex;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}
.samar-logo {
  height: 54px;
}
.navbar-collapse {
  padding: 0px 22px;
}
.dropdown-menu {
  background: #00bcd4 !important;
  color: #ffffff !important;
  border-radius: 0 !important;
  border: 0;
  width: 90%;
}
.dropdown-menu > a {
  border-bottom: 1px solid;
}
.dropdown-menu > a:last-child {
  border-bottom: 0;
}
.dropdown-item {
  background-color: #00bcd4 !important;
  color: #fff !important;
  font-weight: bold !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  line-height: 23px;
}
.lo2 {
  padding-left: 24px !important;
}
.bg-primary-Sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  color: #fff !important;
  background: #00bcd4 !important;
  transition: 0.4s;
}

.navbar-dark .navbar-nav .tet-sticky {
  font-family: "Montserrat", sans-serif;
  color: #fff !important;
}
.navbar-brand {
  margin-right: 3px !important;
}
@media (max-width: 600px) {
  .bg-primary-Sticky {
    width: 97%;
  }
}
@media (max-width: 800px) {
  /* styles to apply when the viewport width is 600 pixels or less */
  .lo2 {
    display: none;
  }
  .gg {
    height: 264px;
  }
  .saamar_logo_hd {
    padding: 0;
  }
}
@media (min-width:300px) and (max-width: 360px) {
  /* styles to apply when the viewport width is 600 pixels or less */
 
  .saamar_logo_hd {
    /* padding: 0; */
    margin-left: -2px !important;
  }
}
@media (max-width: 1150px) {
  /* styles to apply when the viewport width is between 600 and 800 pixels */
  .lo2 {
    display: none;
  }
}
@media (min-width: 752px) and (max-width: 800px) {
}
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}
