.sgi {
  width: 100% !important;
}
.asp {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #333333;
}
.dsp {
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #7b8a91;
  line-height: 29px;
}
.tbt-data {
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #7b8a91;
}
.snp {
  font-weight: 800;
  color: black;
}
.dspp {
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #7b8a91;
  line-height: 35px;
}
.dspp > ol {
  padding-left: 3rem;
}
.mlo {
  font-weight: bold !important;
}

.tbt::first-letter {
  font-weight: bold;
  color: #333;
}
.mlo {
  font-weight: bold;
  color: #333;
}
/* .trdh {
  width: 20%;
} */

.table.key-details-table > thead > tr > th,
.table.key-details-table > tbody > tr > td {
  padding: 15px 10px;
}
.table.key-sub-table > thead > tr > th,
.table.key-sub-table > tbody > tr > td {
  padding: 15px 10px;
}
.table.key-sub-table {
  width: 20%;
}
/* .table > .key-sub-table::first-letter{
  font-weight: bold;
  color: #333;

} */

@media (max-width: 600px) {
  .table.key-details-table > thead > tr > th,
  .table.key-details-table > tbody > tr > td {
    padding: 12px 3px;
  }
}
